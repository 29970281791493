const ContactUsText = {
  des1: "At Paadyamee IT Solutions Pvt Ltd, we are dedicated to delivering top-notch IT services and support tailored to your unique needs. Whether you have a question, need technical assistance, or want to discuss how we can help optimize your IT infrastructure, we are here to assist you.",
  email: "murali@paadyamee.com",
  personalMail: "muralichinnavula@gmail.com",
  phone: "+91 9247836429",
  Address:
    "D.No: 5-197, Sri Sai Deva Nilayam, First Cross Road Sree Maha Lakshmi Nagar, Poranki, Penumaluru, Andhra Pradesh, India - 521137.",
  Address2:
    "Flat No: 202, 2nd Floor, Prakash Towers, Chinna Thokatta, Bowenpally-1, Hyderbad, Telangana-500011",
};
export default ContactUsText;
