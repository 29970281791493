const ServicesText = {
  UIDdes1:
    "Our UIDAI Enrolment Services provide comprehensive support for obtaining an Aadhaar number through a network of easily accessible Enrolment Centers across India. We streamline the process with online appointment booking, ensuring efficient data collection and biometric capture. Our services include assistance with document verification, enrollment, and updating Aadhaar information, facilitating a seamless and secure identification experience for all residents.",
  EC: "Access to Enrolment Centers",
  ECdes:
    "We provide access to numerous Enrolment Centers across India for convenient Aadhaar registration",
  BD: "Biometric Data Collection",
  BDdes:
    "Our centers efficiently capture essential biometric data, including fingerprints, iris scans, and photographs",
  OAS: "Online Appointment System",
  OASdes:
    "Schedule your enrolment appointments online to reduce wait times and streamline the process",
  DV: "Document Verification",
  DVdes:
    "We assist with verifying required documents to ensure compliance with UIDAI standards",
  AU: "Aadhaar Updates",
  AUdes:
    "Support is available for updating your Aadhaar details, such as address changes, both online and at our centers",
  SI: "Secure Identification",
  SIdes:
    "Our services ensure a secure and accurate Aadhaar registration process, facilitating reliable identification for all residents",
  SDS: "At Paadyamee, we excel at turning innovative ideas into outstanding digital solutions. Our expert team is committed to delivering high-quality products that drive success and growth for your business. By leveraging our expertise and cutting-edge technologies, we ensure that every project we undertake meets the highest standards of excellence.",
  WD: "Web Development",
  WDdes:
    "Our Web Development team crafts responsive and engaging websites tailored to your business needs. Utilizing the latest technologies and best practices, we ensure your site is not only visually appealing but also functional and optimized for performance across all devices.",
  MAD: "Mobile App Development",
  MADdes:
    "We specialize in developing high-performance mobile apps for both iOS and Android platforms. From intuitive user interfaces to robust back-end systems, our solutions are designed to provide a seamless user experience and drive user engagement.",
  STA: "Software Testing and Analysis",
  STAdes:
    "Our comprehensive Software Testing and Analysis services ensure your applications are reliable and bug-free. We employ both manual and automated testing methods to deliver high-quality software that performs well under all conditions and is secure against potential threats.",
  UXD: "UI/UX Design",
  UXDdes:
    "Our UI/UX Design team focuses on creating intuitive and visually stunning interfaces that enhance user satisfaction. By combining user research with creative design, we deliver solutions that are both functional and delightful, improving user interaction and engagement with your product.",
};
export default ServicesText;

// Web Development
// Our Web Development team crafts responsive and engaging websites tailored to your business needs. Utilizing the latest technologies and best practices, we ensure your site is not only visually appealing but also functional and optimized for performance across all devices.

// Mobile App Development
// We specialize in developing high-performance mobile apps for both iOS and Android platforms. From intuitive user interfaces to robust back-end systems, our solutions are designed to provide a seamless user experience and drive user engagement.

// Software Testing and Analysis
// Our comprehensive Software Testing and Analysis services ensure your applications are reliable and bug-free. We employ both manual and automated testing methods to deliver high-quality software that performs well under all conditions and is secure against potential threats.

// UI/UX Design
// Our UI/UX Design team focuses on creating intuitive and visually stunning interfaces that enhance user satisfaction. By combining user research with creative design, we deliver solutions that are both functional and delightful, improving user interaction and engagement with your product.
