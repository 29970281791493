// Company Culture: Highlight your company’s values, mission, and the unique aspects of your workplace culture.

// Team Collaboration: Describe how teams work together, including any collaborative tools or practices that enhance teamwork.

// Employee Development: Showcase opportunities for growth, such as training programs, workshops, and career advancement.

// Office Environment: Provide insights into your office space or remote work setup, including amenities, design, and any unique features.

// Employee Recognition: Mention how you recognize and reward employees for their hard work and achievements.

// Work Environment: Provide information about the day-to-day atmosphere, including teamwork, support from leadership, and general morale.

const CareerText = {
  textArray: [
    {
      id: 1,
      title: "Company Culture",
      des: "The company values integrity, collaboration, and innovation, delivering high-quality IT solutions. It embraces diversity and supports continuous learning while promoting a healthy work-life balance, creating an environment where employees thrive.",
    },
    {
      id: 2,
      title: "Team Collaboration",
      des: "Teamwork thrives on clear communication, defined roles, and collaborative tools that streamline workflow, document sharing, and task management. Agile practices, virtual tools, and a supportive environment enable efficient collaboration, even in remote or hybrid settings.",
    },
    {
      id: 3,
      title: "Employee Development",
      des: "Opportunities for growth include training programs, challenging projects, and clear career advancement paths, helping team members enhance skills and progress within the company.",
    },
    {
      id: 4,
      title: "Office Environment",
      des: "Our office space is designed for collaboration and comfort, featuring open work areas, meeting rooms, breakout zones, and ergonomic workstations. We also provide a flexible setup for remote work, ensuring a productive environment whether you are in the office or working from home.",
    },
    {
      id: 5,
      title: "Employee Recognition",
      des: "Employees are recognized and rewarded through performance-based bonuses, public recognition during company events, Employee of the Month programs, peer nominations, and personalized rewards like gift vouchers. These initiatives ensure they feel valued and motivated.",
    },
    {
      id: 6,
      title: "Work Environment",
      des: "The day-to-day atmosphere is collaborative and positive, emphasizing teamwork and support from approachable leadership. Open communication fosters high morale, creating a motivating environment where employees feel valued and connected.",
    },
  ],
};

export default CareerText;
