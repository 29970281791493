import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";

import Footer from "./pages/footer/footer";
import Header from "./pages/header/header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Career from "./pages/Career/Career";
import Contact from "./pages/Contact/Contact";
import ScrollToTop from "./ScrolltoTop";

function App() {
  return (
    <div className="container">
      {/* <BrowserRouter> */}
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

      <Footer />
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
